
const je = require('json-encrypt')
let user_permissions = window.localStorage.getItem("user_permissions");
user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));

var modules = [{
  _name: 'CSidebarNavItem',
  name: 'Inicio',
  to: '/inicio/inicio',
  icon: 'cilHome',
}];


if(user_permissions.indexOf('RoomTypeList') > -1 || user_permissions.indexOf('ServiceList') > -1 || user_permissions.indexOf('BedList') > -1){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('RoomTypeList') > -1) {
    item.push({ name: 'Departamentos', to: '/tipo-habitacion/listar' });
  }
  if (user_permissions.indexOf('ServiceList') > -1) {
    item.push({ name: 'Servicios', to: '/servicio/listar' });
  }
  if (user_permissions.indexOf('BedList') > -1) {
    item.push({ name: 'Camas', to: '/cama/listar' });
  }
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Departamentos',
    icon: 'cilBed',
    items: item
  }
  modules.push(TypeUser);
}

if(user_permissions.indexOf('GuestList') > -1 ){
  let me = this;

  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Huespedes',
    to: '/huesped/listar',
    icon: 'cilPeople',
  }
  modules.push(TypeUser);
}
if(user_permissions.indexOf('RateList') > -1 ){
  let me = this;

  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Disponibilidad',
    to: '/tarifa/listar',
    icon: 'cil-applications',
  }
  modules.push(TypeUser);
}

if(user_permissions.indexOf('ReservationList') > -1 ){
  let me = this;

  let TypeUser = {
    _name: 'CSidebarNavItem',
    name: 'Reservas',
    to: '/reserva/listar',
    icon: 'cilCalendarCheck',
  }
  modules.push(TypeUser);
}



if(user_permissions.indexOf('UserList') > -1 || user_permissions.indexOf('UserTypeList') > -1  || user_permissions.indexOf('VoucherpeList') > -1 || user_permissions.indexOf('Business') > -1){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('UserList') > -1) {
    item.push({ name: 'Usuario', to: '/usuario/listar' });
    
  }
  if (user_permissions.indexOf('UserTypeList') > -1) {
    item.push({ name: 'Tipo de Usuario', to: '/tipo-usuario/listar'});
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Mantenimiento',
    icon: 'cilCog',
    items: item
  }
  modules.push(TypeUser);
}



if(user_permissions.indexOf('WebPageList') > -1){
  let me = this;
  let item = [];
  item.push({ name: 'Portada',to: '/portadas/listar'});
  item.push({ name: 'Promociones',to: '/promociones/listar'});
  item.push({ name: 'Comentarios',to: '/comentarios/listar'});
  item.push({ name: 'Suscripciones',to: '/suscripciones/listar'});

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Pagina Web',
    icon: 'cilStar',
    items: item
  }
  modules.push(TypeUser);
}



export default [
  {
    _name: 'CSidebarNav',
    _children: modules
  }
]